import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { GithubIcon } from "../components/Icons/GithubIcon";
import Nav from "../components/Nav/Nav";
import Footer from "../components/Footer/Footer";
import Animate from "../components/IntersectionAnimator/Animate";
import Seo from "../components/SEO/SEO";
import { ThemeProvider } from "../contexts/Theme/ThemeProvider";

// import repos from "./repos";
// console.log(repos)

const ProjectArchive = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [repos, setRepos] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    fetch("https://api.github.com/users/sagar-13/repos")
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoading(false);

          if (Array.isArray(result)) {
            setRepos(result);
          } else {
            setError(
              result?.message || "There was a problem fetching projects."
            );
          }
        },
        (error) => {
          setIsLoading(false);
          setError(error);
        }
      );
  }, []);

  return (
    <ThemeProvider>
      <Seo title="Project Archive" />
      <Nav />
      <div className="archive">
        <Link className="back-btn" to="/">
          <h3>Back</h3>
        </Link>
        <h2>Project Archive</h2>
        {isLoading ? (
          <div>Loading...</div>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <div className="repo-list">
            {Array.isArray(repos) &&
              repos.map((repo) => {
                return (
                  <Animate direction={"up"}>
                    <div className="repo" key={repo.name}>
                      <h3>{repo.name}</h3>

                      <a href={repo.html_url}>
                        <GithubIcon />
                      </a>

                      {repo.homepage && (
                        <a href={repo.homepage}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24"
                            viewBox="0 0 24 24"
                            width="24"
                          >
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z" />
                          </svg>
                        </a>
                      )}
                      <p>{repo.description}</p>
                    </div>
                  </Animate>
                );
              })}{" "}
          </div>
        )}{" "}
      </div>
      <Footer />
    </ThemeProvider>
  );
};

export default ProjectArchive;
